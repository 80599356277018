section.homepage-callouts:not(.full) {
  position: relative;
  overflow: hidden;
  background: #002545;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background: url('/themes/medusa/assets/dist/images/diagonal-top-and-bottom-white.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 52px;
    z-index: 0;
    opacity: .1;

    @media (min-width: 1025px) {
      height: 103px;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:focus {
    outline: none;
  }

  &:nth-of-type(odd) {
    background: none;

    &:before,
    &:after {
      display: none;
    }

    .call-outs {
      background: white;

      .call-out {
        .box-content {
          order: 2;

          p {
            color: inherit;
          }

          h2 {
            color: black;
          }

          a.btn-primary {
            &:hover,
            &:focus {
              background: #001d41;
              color: white;
            }
          }

          .buy-button-info {
            color: black;
          }
        }

        .box-image {
          order: 1;
        }
      }
    }
  }

  .call-outs {
    position: relative;
    display: block;
    padding: 40px;
    width: 100vw;
    z-index: 1;

    @media (min-width: 1025px) {
      display: inline-flex;
      // padding: 80px 150px;
      padding: 80px 110px;
      flex-wrap: wrap;
    }

    .call-out {
      position: relative;
      display: block;
      align-items: center;
      // max-width: 1240px;
      max-width: 1600px;
      width: 100%;
      margin: auto;
      padding: 0 0;

      @media (min-width: 1025px) {
        display: inline-flex;
        padding: 80px 0;
      }

      .box-image,
      .box-content {
        padding: 25px 20px 35px;
        width: 100%;

        @media (min-width: 1025px) {
          padding: 25px 40px 35px;
        }
      }

      .box-image {
        @media (min-width: 1025px) {
          width: 67%;
        }
      }

      .box-content {
        text-align: center;

        @media (min-width: 1025px) {
          text-align: left;
          position: relative;
          width: 33%;
        }

        h2 {
          color: white;
          font-size: 30px;
          line-height: 30px;
          margin-top: 0;
          text-transform: none;
        }

        p {
          color: white;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 35px;
          font-weight: 600;
        }

        a.btn-primary {
          display: inline-block;
          text-transform: uppercase;
        }

        .buy-button-info {
          display: block;
          color: white;
          font-style: italic;
          margin: 15px 0px;

          &.hide {
            display: none !important;
          }
        }
      }
    }
  }

  &.secondary {
    .call-out {
      padding-bottom: 0;

      .box-image {
        padding-bottom: 0;
      }
    }

    .secondary-call-out {
      position: relative;
      display: block;
      align-items: center;
      max-width: 1600px;
      width: 100%;
      margin: auto;
      padding: 0 0;

      @media (min-width: 1025px) {
        display: inline-flex;
        padding: 0;
      }
      
      .box-content {
        padding: 0 20px 35px;
        width: 100%;

        @media (min-width: 1025px) {
          padding: 25px 40px 35px;
        }
      }
    }
  }
}

#madeinusa {
  padding: 40px 0;

  @media (min-width: 1025px) {
    padding: 0;
  }

  a.btn-primary {
    display: none;
  }
}

.homepage-callouts.full {
  height: 100%;
  min-height: 470px;

  .content {
    width: 100%;
  }

  .call-outs {
    .call-out {
      position: relative;
      display: flex;
      background: #001d41;
      height: 100%;
      min-height: 450px;
      width: 100%;

      @media (min-width: 1025px) {
        display: inline-flex;
        align-items: center;
      }

      .box-image,
      .box-content {
        width: 100%;

        @media (min-width: 1025px) {
          width: 50%;
        }
      }

      .box-image {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 725px;

        @media (min-width: 1025px) {
          min-height: 800px;
        }
      }

      .box-content {
        padding: 25px 20px 35px;
        text-align: center;

        @media (min-width: 1025px) {
          padding: 100px 125px;
          position: relative;
          float: right;
        }

        h2 {
          color: white;
          font-size: 30px;
          line-height: 30px;
          padding: 0;
          text-transform: none;
        }
      }
    }
  }

  .three-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;

    @media (min-width: 1025px) {
      flex-wrap: nowrap;
    }

    .col {
      text-align: center;
      padding: 0 47px 0;
      background-position: center;
      background-size: cover;
      // width: 100vw;

      @media (min-width: 1025px) {
        max-width: 435px;
        background-position: center bottom;
      }
    }

    .box-image,
    .box-content {
      width: 100% !important;
      padding: 0 !important;
      background-position: center bottom !important;
      font-size: 29px;
    }

    .box-image {
      min-height: 950px !important;

      @media (min-width: 1025px) {
        min-height: 800px !important;
      }
    }
  }
}

.call-out-footer {
  position: relative;
  background: #001937;
  width: 100%;
  padding: 40px;
  bottom: 0;
  z-index: 1;

  @media (min-width: 1025px) {
    padding: 40px 110px;
  }

  // @media (min-width: 1920px) {
  //   position: absolute;
  //   bottom: 0;
  // }

  > div {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;

    h2,
    h3,
    h4 {
      width: 100%;
      color: white;
      margin-bottom: 25px;
    }

    h4 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    a {
      color: white;
      word-break: break-word;

      &:hover,
      &:focus {
        color: $primary-color;
      }
    }
  }

  .three-column {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }
}

.homepage-callouts.with-footer {
  min-height: auto;
  padding: 40px 0 0;

  @media (min-width: 1025px) {
    padding: 0;
  }

  &:after {
    display: none !important;
  }
}