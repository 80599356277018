.homepage {
  section {
    display: flex;
    justify-content: unset;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100%;
    @include medium-up {
      min-height: 100vh;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // height: 100vh;
      }
    }

    .content {
      margin: auto;
    }
  }
}