/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 768px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1900px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1900px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1899px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1900px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1900px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 768px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1900px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1900px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1899px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1900px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1899px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1900px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 768px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1900px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1900px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.homepage section {
  display: flex;
  justify-content: unset;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100%;
}
@media (min-width: 768px) {
  .homepage section {
    min-height: 100vh;
  }
}
.homepage section .content {
  margin: auto;
}

section.homepage-callouts:not(.full) {
  position: relative;
  overflow: hidden;
  background: #002545;
}
section.homepage-callouts:not(.full):before, section.homepage-callouts:not(.full):after {
  content: "";
  position: absolute;
  display: block;
  background: url("/themes/medusa/assets/dist/images/diagonal-top-and-bottom-white.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 52px;
  z-index: 0;
  opacity: 0.1;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full):before, section.homepage-callouts:not(.full):after {
    height: 103px;
  }
}
section.homepage-callouts:not(.full):before {
  top: 0;
}
section.homepage-callouts:not(.full):after {
  bottom: 0;
}
section.homepage-callouts:not(.full):focus {
  outline: none;
}
section.homepage-callouts:not(.full):nth-of-type(odd) {
  background: none;
}
section.homepage-callouts:not(.full):nth-of-type(odd):before, section.homepage-callouts:not(.full):nth-of-type(odd):after {
  display: none;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs {
  background: white;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content {
  order: 2;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content p {
  color: inherit;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content h2 {
  color: black;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content a.btn-primary:hover, section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content a.btn-primary:focus {
  background: #001d41;
  color: white;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-content .buy-button-info {
  color: black;
}
section.homepage-callouts:not(.full):nth-of-type(odd) .call-outs .call-out .box-image {
  order: 1;
}
section.homepage-callouts:not(.full) .call-outs {
  position: relative;
  display: block;
  padding: 40px;
  width: 100vw;
  z-index: 1;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full) .call-outs {
    display: inline-flex;
    padding: 80px 110px;
    flex-wrap: wrap;
  }
}
section.homepage-callouts:not(.full) .call-outs .call-out {
  position: relative;
  display: block;
  align-items: center;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding: 0 0;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full) .call-outs .call-out {
    display: inline-flex;
    padding: 80px 0;
  }
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-image,
section.homepage-callouts:not(.full) .call-outs .call-out .box-content {
  padding: 25px 20px 35px;
  width: 100%;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full) .call-outs .call-out .box-image,
section.homepage-callouts:not(.full) .call-outs .call-out .box-content {
    padding: 25px 40px 35px;
  }
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full) .call-outs .call-out .box-image {
    width: 67%;
  }
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content {
  text-align: center;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full) .call-outs .call-out .box-content {
    text-align: left;
    position: relative;
    width: 33%;
  }
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content h2 {
  color: white;
  font-size: 30px;
  line-height: 30px;
  margin-top: 0;
  text-transform: none;
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content p {
  color: white;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 35px;
  font-weight: 600;
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content a.btn-primary {
  display: inline-block;
  text-transform: uppercase;
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content .buy-button-info {
  display: block;
  color: white;
  font-style: italic;
  margin: 15px 0px;
}
section.homepage-callouts:not(.full) .call-outs .call-out .box-content .buy-button-info.hide {
  display: none !important;
}
section.homepage-callouts:not(.full).secondary .call-out {
  padding-bottom: 0;
}
section.homepage-callouts:not(.full).secondary .call-out .box-image {
  padding-bottom: 0;
}
section.homepage-callouts:not(.full).secondary .secondary-call-out {
  position: relative;
  display: block;
  align-items: center;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding: 0 0;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full).secondary .secondary-call-out {
    display: inline-flex;
    padding: 0;
  }
}
section.homepage-callouts:not(.full).secondary .secondary-call-out .box-content {
  padding: 0 20px 35px;
  width: 100%;
}
@media (min-width: 1025px) {
  section.homepage-callouts:not(.full).secondary .secondary-call-out .box-content {
    padding: 25px 40px 35px;
  }
}

#madeinusa {
  padding: 40px 0;
}
@media (min-width: 1025px) {
  #madeinusa {
    padding: 0;
  }
}
#madeinusa a.btn-primary {
  display: none;
}

.homepage-callouts.full {
  height: 100%;
  min-height: 470px;
}
.homepage-callouts.full .content {
  width: 100%;
}
.homepage-callouts.full .call-outs .call-out {
  position: relative;
  display: flex;
  background: #001d41;
  height: 100%;
  min-height: 450px;
  width: 100%;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .call-outs .call-out {
    display: inline-flex;
    align-items: center;
  }
}
.homepage-callouts.full .call-outs .call-out .box-image,
.homepage-callouts.full .call-outs .call-out .box-content {
  width: 100%;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .call-outs .call-out .box-image,
.homepage-callouts.full .call-outs .call-out .box-content {
    width: 50%;
  }
}
.homepage-callouts.full .call-outs .call-out .box-image {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 725px;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .call-outs .call-out .box-image {
    min-height: 800px;
  }
}
.homepage-callouts.full .call-outs .call-out .box-content {
  padding: 25px 20px 35px;
  text-align: center;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .call-outs .call-out .box-content {
    padding: 100px 125px;
    position: relative;
    float: right;
  }
}
.homepage-callouts.full .call-outs .call-out .box-content h2 {
  color: white;
  font-size: 30px;
  line-height: 30px;
  padding: 0;
  text-transform: none;
}
.homepage-callouts.full .three-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .three-column {
    flex-wrap: nowrap;
  }
}
.homepage-callouts.full .three-column .col {
  text-align: center;
  padding: 0 47px 0;
  background-position: center;
  background-size: cover;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .three-column .col {
    max-width: 435px;
    background-position: center bottom;
  }
}
.homepage-callouts.full .three-column .box-image,
.homepage-callouts.full .three-column .box-content {
  width: 100% !important;
  padding: 0 !important;
  background-position: center bottom !important;
  font-size: 29px;
}
.homepage-callouts.full .three-column .box-image {
  min-height: 950px !important;
}
@media (min-width: 1025px) {
  .homepage-callouts.full .three-column .box-image {
    min-height: 800px !important;
  }
}

.call-out-footer {
  position: relative;
  background: #001937;
  width: 100%;
  padding: 40px;
  bottom: 0;
  z-index: 1;
}
@media (min-width: 1025px) {
  .call-out-footer {
    padding: 40px 110px;
  }
}
.call-out-footer > div {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
.call-out-footer > div h2,
.call-out-footer > div h3,
.call-out-footer > div h4 {
  width: 100%;
  color: white;
  margin-bottom: 25px;
}
.call-out-footer > div h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}
.call-out-footer > div a {
  color: white;
  word-break: break-word;
}
.call-out-footer > div a:hover, .call-out-footer > div a:focus {
  color: #f7641f;
}
.call-out-footer .three-column {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.homepage-callouts.with-footer {
  min-height: auto;
  padding: 40px 0 0;
}
@media (min-width: 1025px) {
  .homepage-callouts.with-footer {
    padding: 0;
  }
}
.homepage-callouts.with-footer:after {
  display: none !important;
}